
import { Component, Prop, Vue } from "vue-property-decorator";

type Tile = {
  name: string;
  contentable: {
    image?: string;
  };
};

@Component({
  components: {
    FormBuilder: () => import("@/app/modules/service/builder/FormBuilder.vue")
  }
})
export default class ServiceComponent extends Vue {
  @Prop(Object) readonly tile!: Tile;

  addInfoToProfile() {
    this.$router.push({
      name: "profile",
      query: {
        redirect: this.$route.path
      }
    });
  }
}
